$theme-colors: (
    "success": #56ab47,
    "primary": #1390cf,
    "info": #feed10
);

@import "bootstrap/scss/bootstrap";

.action-toggler.download,
.matter-toggler.download {
    color: theme-color-level("success", 4);
}

.action-toggler.download:before,
.matter-toggler.download:before {
    background-color: theme-color-level("success", -11);
}

.action-toggler.download:hover:before,
.matter-toggler.download:hover:before {
    background-color: theme-color-level("success", -10);
}

.action-toggler.reload,
.matter-toggler.reload {
    color: theme-color-level("warning", 4);
}

.action-toggler.reload:before,
.matter-toggler.reload:before {
    background-color: theme-color-level("warning", -11);
}

.action-toggler.reload:hover:before,
.matter-toggler.reload:hover:before {
    background-color: theme-color-level("warning", -10);
}

.action-toggler.unload,
.matter-toggler.unload {
    color: theme-color-level("danger", 4);
}

.action-toggler.unload:before,
.matter-toggler.unload:before {
    background-color: theme-color-level("danger", -11);
}

.action-toggler.unload:hover:before,
.matter-toggler.unload:hover:before {
    background-color: theme-color-level("danger", -10);
}

.action-toggler:hover:before,
.matter-toggler:hover:before {
    background-color: $gray-100;
}
