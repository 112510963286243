.matter {
    text-align: center;
}

.cursor-wait {
    cursor: wait !important;
}

.matter-toggler {
    cursor: pointer;
    padding: 0.2rem;
    margin-top: 1rem;
    user-select: none;
}

.matter-toggler__wrapper {
    position: relative;
}

.matter-toggler:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.matter-toggler.none {
    color: silver;
}

.matter-toggler .matter {
    position: relative;
    z-index: 1;
}

.delete {
    visibility: hidden;
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;
}

.delete:hover {
    color: black;
}

.matter-toggler:hover .delete {
    visibility: visible;
}
