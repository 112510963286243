.points-view .point-name {
    text-align: left;
}

.matters-list {
    display: flex;
    margin: 0;
    padding: 0;
}

.matters-list__item {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: pointer;
}

.strong {
    font-weight: bold !important;
}

.col-name {
    min-width: 250px;
}

.col-hours {
    min-width: 150px;
}

.col-manager {
    min-width: 300px;
}

.col-comment {
    width: 100%;
}
